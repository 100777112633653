import { Iobject } from '@mobro/libs/es/type';

// 文件存储的位置
export const OSS_STORAGE_DIR = ['other', 'news/article_pics', 'news/poster', 'news/share_pics', 'common'];

// 查询对应位置的文件列表，加 ‘/’ 是为了兼容直接从阿里 OSS 取列表出来
export const QUERY_OSS_STORAGE_DIR = OSS_STORAGE_DIR.map(s => s + '/');

export const QUERY_PIC_ORDER_BY = ['nowFileName', 'updateTime', 'originName'];
export enum QUERY_ORDER_BY_VALUE {
    desc = 'DESC',
    asc = 'ASC',
}

export const API_HOST = process.env.VUE_APP_API_HOST;
export const ONLINE_HOST = process.env.VUE_APP_ONLINE_HOST;
export const OSS_HOST = process.env.VUE_APP_OSS_HOST;
export const LOGIN_HOST = process.env.VUE_APP_LOGIN_URL;

export const NEWS_ORIGIN_LIST: [string, number][] = [
    ['央视新闻', 1],
    ['澎湃新闻', 4],
    ['界面新闻', 4],
    ['海外网', 1],
    ['极目新闻', 3],
    ['联合早报', 2],
    ['中新网', 2],
    ['沸点视频', 2],
    ['北京日报', 2],
    ['ZARKER', 1],
    ['九派新闻', 2],
    ['36氪', 1],
    ['红星新闻', 2],
    ['成都商报', 2],
    ['潇湘晨报', 2],
    ['九派新闻', 2],
    ['中国新闻网', 2],
    ['正观新闻', 2],
    ['每日经济新闻', 2],
];

export function map2list(map: Iobject): { key: string; value: string | number; label: string }[] {
    return Object.entries(map).map(([value, label]) => {
        return {
            value,
            label,
            key: value,
        };
    });
}

export const COUNTERPART_MAP = {
    0: '否',
    1: '是',
};

export const COUNTERPART_LIST = map2list(COUNTERPART_MAP);

export const NORAML_PAGE_SIZE = [7, 10, 20, 50, 100, 5000];
