import Vue, { PluginFunction } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import '@/libs/element-ui';
import { getRouterConfig } from './router';
import store from './store';
import { setRequestConfig } from '@mobro/request';
import '@mobro/libs/assets/reset.less';
import Vconsole from 'vconsole';
import SearchCol from '@/components/SearchCol.vue';
import { API_HOST } from './store/constants';
import VueRouter from 'vue-router';

setRequestConfig({ baseURL: API_HOST, withCredentials: true });

if (process.env.NODE_ENV !== 'production') {
    const vConsole = new Vconsole() as unknown as PluginFunction<unknown>;
    Vue.use(vConsole);
}

Vue.component('SearchCol', SearchCol);

getRouterConfig().then((router: VueRouter) => {
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
});
