import { getCookie, setCookie, setLocalStorage, getLocalStorage, uncompileStr } from '@mobro/libs';
import { Ifunction, Iobject } from '@mobro/libs/es/type';
import { IRequestMethod, request } from '@mobro/request';
import { Message } from 'element-ui';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
    public status = false;
    public token = '';
    public userInfo: Iobject = {};

    public onToggleLogin(status: boolean): void {
        this.status = status;
    }

    created(): void {
        this.token = getCookie('token') || '';
        this.userInfo = getLocalStorage('USER_INFO') || {};

        this.onToggleLogin(!!this.token && JSON.stringify(this.userInfo) !== '{}');
    }

    public login(userinfo: Iobject): Promise<Iobject> {
        return new Promise((resolve: Ifunction) => {
            const isPhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g.test(
                userinfo.userName,
            );

            request({
                url: '/api/auth/login',
                method: IRequestMethod.POST,
                params: isPhone
                    ? { phone: userinfo.userName, password: userinfo.pass }
                    : { uuid: userinfo.userName, password: userinfo.pass },
                needAllData: true,
            }).then((res: Iobject) => {
                if (res.respCode > 10000) {
                    Message.error(res.msg);
                    return;
                }
                this.userInfo = res.data;
                resolve({ status: true, userInfo: res.data });
                setLocalStorage('USER_INFO', res.data, 7);

                location.reload();
            });
        });
    }

    public logout(): void {
        setLocalStorage('USER_INFO', '', -1);
        setCookie('token', '', -1);
        location.reload();
    }
}
