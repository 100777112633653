
import { Component, Vue } from 'vue-property-decorator';
import { Iobject } from '@mobro/libs/es/type';
import { getCookie, setCookie } from '@mobro/libs';
import { IRequestMethod, request } from '@mobro/request';
import { LOGIN_HOST } from './store/constants';

@Component({})
export default class App extends Vue {
    private activeNavName = '';
    userInfo: Iobject = {};
    menuList: Iobject[] = [];

    created() {
        const uuid = getCookie('uuid');

        if (!uuid) {
            this.goLoginPage();
            return;
        } else {
            request({
                url: `/api/auth/query/${uuid}`,
                method: IRequestMethod.GET,
                params: {},
                needGlobalTips: true,
            }).then(res => {
                this.userInfo = {
                    ...(res || {}),
                    // avatarUrl: res.avatarUrl || require('@/assets/telcharges/poster_header.png'),
                };
            });
        }
        const ex = /.*#\/(.+)/.exec(location.hash);
        if (ex) {
            this.activeNavName = ex[1];
        }
    }

    onDropDownItemClick(x: string) {
        switch (x) {
            case 'logout':
                request({
                    url: '/api/auth/logout',
                    method: IRequestMethod.GET,
                    params: {},
                }).then(res => {
                    if (res) {
                        this.goLoginPage();
                    }
                });

                return;
            default:
                console.log(x, '______');
        }
    }

    goLoginPage() {
        const loginUrl = `${LOGIN_HOST}?redirect=${encodeURIComponent(location.href)}`;
        console.log('登录地址： ', loginUrl);

        window.location.replace(`${LOGIN_HOST}?redirect=${encodeURIComponent(location.href)}`);
    }

    onMenuItemClick(navName: string) {
        this.activeNavName = navName;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get menuListMemo(): { [keyName: string]: any; children?: Iobject[] }[] {
        return JSON.parse(sessionStorage.getItem('menuList') || '[]');
    }
}
